import React from 'react';

const BuildSdeCareer2024 = () => {
  let search = '';

  if (typeof window !== 'undefined') {
    search = window.location?.search;
  }

  return (
    <div className="h-[100dvh] w-[100dvw]">
      <iframe
        title="Embedded Page"
        src={`https://qift.viewpage.co/build-sde-career-2024/${search}`}
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </div>
  );
};

export default BuildSdeCareer2024;
